<template>
  <el-dialog
    v-if="showAddCategory"
    :title="categoryForm.id ? '编辑' : '新增'"
    :visible="showAddCategory"
    width="504px"
    class="dialog-vertical"
    @close="closeAdd"
    close-on-click-modal
    close-on-press-escape
  >
    <el-form
      :model="categoryForm"
      size="medium"
      label-width="100px"
      :rules="form_rules"
      ref="categoryForm"
      @submit.native.prevent
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="categoryForm.name"
          placeholder="请填写"
          v-focus
        ></el-input>
      </el-form-item>
      <el-form-item label="设施" prop="facility_ids">
        <el-checkbox-group v-model="categoryForm.facility_ids">
          <el-checkbox v-for="f in facilities" :key="f.id" :label="f.id">{{f.name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="显示">
        <el-radio v-model="categoryForm.is_show" :label="1">显示</el-radio>
        <el-radio v-model="categoryForm.is_show" :label="0">不显示</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="saveLoading"
        @click="handleAddCategory('categoryForm')"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { addOrEditVenueCategory } from '../../api/category'
import {getAllFacilities} from '../../api'

export default {
  name: 'AddCategory',
  props: ['showAddCategory', 'editObj'],
  data() {
    return {
      categoryForm: {
        is_show: 1,
        name: '',
        id: 0,
        facility_ids: [],
      },
      form_rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
      },
      saveLoading: false,
      facilities: []
    }
  },
  watch: {
    editObj() {
      if (this.editObj.name) {
        this.categoryForm = {
          ...this.editObj,
          facility_ids: this.editObj.facilities.map(f => f.id)
        }
      }
    },
  },
  created() {
    getAllFacilities().then(({data}) => {
      this.facilities = data.filter(d => !!d.is_show)
    }).catch(() => {
    })
  },
  methods: {
    handleAddCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          addOrEditVenueCategory(this.categoryForm)
            .then((res) => {
              this.$emit('updateData')
              this.categoryForm = {
                is_show: 1,
                name: '',
                id: 0,
                facility_ids: [],
              }
              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    closeAdd() {
      this.categoryForm = {
        is_show: 1,
        name: '',
        id: 0,
        facility_ids: [],
      }
      this.$refs.categoryForm.clearValidate()
      this.$emit('closeAddDialog')
    },
  },
}
</script>

<style scoped></style>
