<template>
  <el-dialog
    v-if="showTransfer"
    title="数据转移"
    :visible="showTransfer"
    width="504px"
    class="dialog-vertical"
    @close="closeAdd"
    close-on-click-modal
    close-on-press-escape
  >
    <el-form
      :model="transferForm"
      size="medium"
      label-width="100px"
      :rules="form_rules"
      ref="transferForm"
      v-loading="optionList.length === 0"
      @submit.native.prevent
    >
      <el-form-item label="选择分类" prop="target_id">
        <el-select v-model="transferForm.target_id" placeholder="请选择">
          <el-option
            v-for="item in optionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="saveLoading"
        @click="handleTransferCategory('transferForm')"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { getVenueCategory, moveVenueCategory } from '../../api/category'

export default {
  name: 'CategoryTransfer',
  props: ['showTransfer', 'transferId', 'updateList'],
  data() {
    return {
      transferForm: {
        id: '',
        target_id: '',
      },
      optionList: [],
      form_rules: {
        target_id: [
          { required: true, message: '请选择分类', trigger: 'change' },
          { required: true, validator: this.transferPass, trigger: 'change' },
        ],
      },
      saveLoading: false,
    }
  },
  watch: {
    transferId() {
      this.transferForm.id = this.transferId
    },
    showTransfer(val) {
      if (val) {
        this.getAllCategory()
      } else {
        this.optionList = []
      }
    },
  },
  methods: {
    getAllCategory() {
      getVenueCategory()
        .then((res) => {
          this.optionList = res.data
            .filter((el) => el.id !== -1 && el.id !== '')
            .filter((el) => el.id !== this.transferForm.id)
        })
        .catch(() => {})
    },
    //验证所选分类是否重复
    transferPass(rule, value, callback) {
      if (value === this.transferId) {
        callback(new Error('与当前分类重复'))
      } else {
        callback()
      }
    },
    handleTransferCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          moveVenueCategory(this.transferForm)
            .then((res) => {
              this.$message.success(res.msg)
              this.$emit('transferSuccess')
              this.updateList()
              this.transferForm = {
                id: '',
                target_id: '',
              }
              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeAdd() {
      this.$emit('closeTransfer')
    },
  },
}
</script>

<style scoped></style>
